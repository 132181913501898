<template>
<div class="container border-container">
    <el-row class="info-container" type="flex" align="middle" justify="space-between">
        <div class="info-tabs">
            <span>订阅记录</span>
        </div>
    </el-row>
    <div class="table-container">
        <el-table :data="tableData" tooltip-effect="dark" style="width: 100%" size="medium"
            :header-cell-style="{height: '40px', background: 'linear-gradient(180deg,rgba(251,252,253,1) 0%,rgba(246,248,250,1) 100%)'}"
            :cell-style="{height: '50px',padding: '5px 0'}" header-align="center">
            <el-table-column prop="order_sn" label="单号" min-width="120" align="center"/>
            <el-table-column prop="increased_time" label="增加天数" min-width="120" align="center">
                <template slot-scope="scope">
                    {{(scope.row.increased_time / 86400).toFixed(2) || '-'}}天
                </template>
            </el-table-column>
            <el-table-column prop="role" label="到期时间" min-width="155" align="center">
                <template slot-scope="scope">
                    {{scope.row.expiration_time | timeFilter}}
                </template>
            </el-table-column>
            <el-table-column prop="create_time" label="续期时间" min-width="155" align="center">
                <template slot-scope="scope">
                    {{scope.row.create_time | timeFilter}}
                </template>
            </el-table-column>
            <div class="" slot="empty">
                <no-data></no-data>
            </div>
        </el-table>
    </div>
    <div class="page-container">
        <el-pagination class="right" layout="total, sizes, prev, pager, next" background
            @size-change="pageSizeChange"
            @current-change="pageChange"
            :current-page="currentPage"
            :page-sizes="[total, 10, 15, 20, 25]"
            :total="total" :page-size="pageSize">
        </el-pagination>
    </div>
</div>
</template>

<script>
import Core from '@/core';
export default {
    components: {
        NoData: () => import('@/components/Empty.vue')
    },
    props: {
        schoolId: {
            type: [String, Number]
        }
    },
    data() {
        return {
            total: 0,
            currentPage: 1,
            pageSize: 10,

            tableData: [],
        };
    },
    computed: {},
    created() {},
    mounted() {
        this.getTableData()
    },
    methods: {
        pageChange(page) {
            this.currentPage = page;
            this.getTableData();
        },
        pageSizeChange(size) {
            this.pageSize = size;
            this.getTableData();
        },

        getTableData() {
            Core.Api.School.renewalRecord(
                this.currentPage,
                this.pageSize,
                this.schoolId,
            ).then(res => {
                console.log("getTableData -> res", res);
                this.total = res.count;
                this.tableData = res.list;
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.container {
    @import '@/common/styles/table.scss';
    .info-container{
        @include flex(row, space-between, center);
        font-size: $font-size-md;
        font-weight: 500;
        color: $color-black;
        line-height: 22px;
        border-bottom: 1px solid rgba(230,234,238,1);
    }
}
</style>
